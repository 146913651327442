import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';

const PaymentUpdate = () => (
  <Layout>
    <SEO
      title="Payment update | Deadline Funnel"
      description="A page for update the card information of user"
    />
    <div style={{ height: 'calc(100vh - 375px)' }}>
      <script dangerouslySetInnerHTML={{
        __html: `
        (function (i, s, o, g, r, a, m) {
        i['ProfitWellObject'] = r;
        i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
      }, i[r].l = 1 * new Date();
        a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', 'https://dna8twue3dlxq.cloudfront.net/js/profitwell.js', 'profitwell');
        profitwell('auth_token', '3a9ef95b3f20d64f43bed259cead2953'); // Your unique Profitwell public API token
        profitwell('user_email', 'USER@EMAIL.COM');   // enter the email address of the logged-in user
      `
      }} />
    </div>
  </Layout>
);

export default PaymentUpdate;